<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <futuro-logo />
        <futuro-text class="ml-1" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="sideImg"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Esqueceu sua senha? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Informe seu e-mail e nós enviaremos instruções para você cadastrar uma nova senha
          </b-card-text>

          <b-form @submit.prevent="onSubmit">
            <b-form-group
              label="E-mail"
              label-for="forgot-password-email"
              class="mb-1"
            >
              <b-form-input
                id="forgot-password-email"
                v-model="email"
                name="forgot-password-email"
                :class="{ 'is-invalid': v$.email.$error }"
                placeholder="E-mail"
              />
              <div class="invalid-feedback">
                <span v-if="v$.email.$invalid">
                  Você deve informar um email válido
                </span>
              </div>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              block
              :disabled="loading"
            >
              {{ loading ? 'Aguarde...' : 'Receber e-mail com instruções' }}
            </b-button>
          </b-form>

          <p class="text-center mt-2">
            <b-link :to="{name:'account-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Voltar para o login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import FuturoLogo from '@core/layouts/components/Logo.vue'
import FuturoText from '@core/layouts/components/LogoText.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { mapActions } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as types from '../store/types'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    FuturoLogo,
    FuturoText
  },
  data() {
    return {
      email: '',
      loading: false,
      sideImg: require('@/assets/images/pages/login-v2.svg')
    }
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate() };
  },
  validations() {
    return {
      email: { required, email }
    }
  },
  methods: {
    ...mapActions({
      recoverPassword: types.RECOVER_PASSWORD,
    }),
    async onSubmit() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return
      this.loading = true
      this.recoverPassword({ email: this.email })
        .then(response => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Instruções enviadas",
              text: 'As instruções para recuperação de senha foram envias para o e-mail informado com',
              icon: "CoffeeIcon",
              variant: "success",
            },
          })
          this.$router.push({ name: 'account-reset-password' })
        })
        .catch(err => {
        if (err.response && err.response.data && err.response.data.error === 'EMAIL_NOT_FOUND') {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "E-mail não encontrado",
              text: 'O e-mail informado não foi encontrado na base de dados. Verifique se digitou corretamente.',
              icon: "AlertTriangleIcon",
              variant: "warning",
            },
          });
        } else {
          this.toast({
            component: ToastificationContent,
            props: {
              title: "Oops!",
              text: 'Ocorreu um erro ao verificar suas credenciais. Entre em contato com o setor de TI.',
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        }
      })
      .finally(() => {
        this.loading = false;
      });
  },
},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
