<template>
  <img :src="imgUrl" :width="width || '98'" />
</template>

<script>
import { $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'
import { USER } from '@/modules/account/store/types'
export default {
  props: {
    dark: Boolean,
    light: Boolean,
    width: String
  },
  computed: {
    ...mapGetters({
      user: USER
    }),
    imgUrl: function(){
      if (this.dark) {
        return 'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/escrita_oraculo_01.svg'
      }
      if (this.light) {
        return 'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/escrita_oraculo_02.svg'
      }
      if (this.user?.dark_mode_admin !== undefined) {
        return this.user?.dark_mode_admin
          ? 'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/escrita_oraculo_01.svg'
          : 'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/escrita_oraculo_02.svg'
      }
      const skin = localStorage.getItem('vuexy-skin') || $themeConfig.layout.skin
      return skin === 'dark'
        ? 'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/escrita_oraculo_01.svg'
        : 'https://futurogfp-assets.s3.sa-east-1.amazonaws.com/escrita_oraculo_02.svg'
    }
  }
}
</script>
